import React, { Component } from "react";

import { Route, Switch } from 'react-router-dom';
import LoginPage from '../../pages/login-page/';
import LogoutPage from '../../pages/logout-page/';
import MainPage from '../../pages/main-page';
import PrivateRoute from '../shared/private-route/'


import "./custom.css";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <div className="container">
      <Switch>
        <PrivateRoute exact path="/" component={MainPage} />
        <Route exact path="/login/" component={LoginPage} />
        <Route exact path="/logout/" component={LogoutPage} />
      </Switch>
      </div>
    );
  }
}
